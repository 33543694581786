import { createPopper } from '@popperjs/core';
import bootstrap from 'bootstrap';
$ = window.$ = window.jQuery = require('jquery');

import smoothScroll from 'jquery-smooth-scroll/src/jquery.smooth-scroll';
$('a[href*="#"]').smoothScroll({
    offset: -112,
    speed: 350
});

let modalVideo              = document.getElementById('modalVideo');
let modalVideoPlayer        = document.getElementById('modalVideoPlayer')
let modalVideoPlayerTitle   = document.getElementById('modalVideoTitle')

modalVideo.addEventListener('shown.bs.modal', function (event) {

    let button = $(event.relatedTarget);
    let videoUrl = button.data('video-src');
    let videoTitle = button.data('video-title');

    if(videoUrl) {
        modalVideoPlayer.src = videoUrl;
        modalVideoPlayerTitle.innerHTML = videoTitle;
        modalVideoPlayer.play();
    }
})
modalVideo.addEventListener('hide.bs.modal', function (event) {
    modalVideoPlayer.src = '';
})
